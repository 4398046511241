.title{
    text-align: center;
}
.container{
    background-color: #151515;
    color: white;
    border-radius: 10px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: center;
}
.value{
    background-color: #1D1D1D;
    padding: 20px;
    text-align: center;
    border-radius : 0 0 10px 10px;
    flex: 2;
}
.value p{
    margin: 0;
    padding: 0;
    font-weight: bold;
}