.dateTimeCustom {
    border: 1px solid #c0c0c0;
    margin-left: 3px;
    padding-right: 2px;
    background-color: white;
    width: 54%;
    height: 27px;
}

.dateTimeCustom > div {
    bottom: 23px;
    left: 15px;
}

.dateTimeCustom > input[type='text'] {
    width: calc(100% - 16px);
    border: none;
    padding-left: 2px;
    outline: none;
    margin-top: 3px;
}

.dateTimeCustom > span {
    font-size: 8px;
    color: black;
    cursor: context-menu;
    position: absolute;
    padding-top: 8px;
}

