.autonomiContainer{
    width: 62%;
    height: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
.buttonsContainer{
    width: 250px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.buttons{
    margin: 2px;
    padding: 10px;
    font-size: 1rem;
    width: 120px;
    height: 70px;
    border-radius: .4rem;
    background-color: var(--firstColor);
    border: none;
    color: var(--textColor);
    cursor: pointer;
}

.buttons:hover{
   background-color: rgb(1, 96, 219);
}

.controlContainer{
    width: 72%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.enableDisableControl{
    width: 100%;
    background-color: var(--firstColor);
    border: none;
    border-radius: 10px;
    height: auto;
    padding: 10px;
    color: var(--textColor);
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputRadioButtons{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    height: 50px;
}

.wasdButtons{
    margin-top: 15px;
    width: 160px;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
}
.wasd{
    width: 50px;
    height: 50px;
    background-color: var(--firstColor);
    border: 1px solid #000;
    border-radius: 5px;
    font-weight: bold;
}
.wasd:hover{
    border: 1px solid yellow;
}
.wasd:first-child{
    margin:0 50px;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.wasd:nth-child(2){
    display:flex;
    justify-content: flex-start;
    align-items: center;
}
.wasd:nth-child(3){
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.wasd:nth-child(4){
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

