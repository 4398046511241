.modalContent {
    position: absolute;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    outline: none;
}

.modalHeader {
    padding: 2px 10px;
    background-color: rgb(63, 81, 181);
    color: white;
    display: flex;
    align-items: center;
}

.headerCloseButtonContainer {
    width: calc(100% - 20px);
}

.alertBodyMainDiv {
    display: flex;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    min-height: 90px;
}

.alertIconDiv {
    width: 20%;
}

.alertMessageDiv {
    width: 80%;
    font-size: 13px;
    padding-top: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.alertButtonDiv {

    text-align: right;
}

.alertButton {
    font-size: 13px;
    margin: 10px;
    background-color: rgb(232, 232, 239);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.20);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 4px;
}

.alertButtonDiv * {
    text-transform: none;
}

.alertHeaderText {
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    position: relative;
    left: -10px;
}
