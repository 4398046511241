@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: var(--componentThirdColor);
    border-radius: 5px;
    width: 30%;
    height: 50%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h2 {
    margin-top: 10px;
    color: rgb(211, 208, 208);
    font-family: 'Quicksand', sans-serif!important;
    font-size: 16px;
    
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    cursor: pointer;
    color: #000;
  }
  .popup .content {
    max-height: 50%;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 30px;
  }
 
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

  .content input {
    margin: 15px 15px 0 0;
  }

  .button{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,-50%);
  }

  .okeyButton{
      padding: 10px;
      width: 150px;
      text-align: center;
      background-color: var(--textColor);
      border:1px solid #000;
  }
  
  @media screen and (max-width: 700px) {
    .popup {
      width: 70%;
    }
  }
  