.floorContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
}

.floorInnerContainer{
    width: 90%;
    height: 75px;
    background: #1d1d1d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.959);
    border-radius: 20px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-bottom:20px;
    overflow-y: hidden;
}

.floorInnerContainer::-webkit-scrollbar {
    height: 7px;
}

.poseInnerContainer{
    width: 90%;
    height: 225px;
    background: #1d1d1d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.959);
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
}

.siteItem,.floorItem,.roomItem{
    background-color: #A0A0A0;
    border: none;
    font-size: 20px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px 30px;
    border-radius: 7px;
    color: aliceblue;
    font-weight: 500;
    margin: 10px;
}

.active{
    background-color: #317FFB;
}