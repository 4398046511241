/* isPopup true dönünce alttaki CSS'ler gerekli olmuyor.
#editable-grid > div > div > div > div > div> div  > div > div > div.ag-row,
#editable-grid > di >*,
#editable-grid > div > div>*,
#editable-grid > div > div > div>*,
#editable-grid > div > div > div > div>*,
#editable-grid > div > div > div > div > div>*,
#editable-grid > div > div > div > div > div> div>*,
#editable-grid > div > div > div > div > div> div > div>*,
#editable-grid > div > div > div > div > div> div > div >div>*,
#editable-grid > div > div > div > div > div> div > div >div > div>*,
#editable-grid > div > div > div > div > div> div > div >div > div > div>*
{
    transform: none!important;
}

*/

.footer {
    display:flex;
    flex-wrap: wrap;
}

.footer button span {
    line-height: initial;
    text-transform: initial;
}