.gridSummaryBar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 8px;
    left: 5px;
    right: 0;
    border-color: rgb(192, 192, 192);
    border-width: thin;
    border-style: solid;
    border-radius: 3px;
    /*height: 27px;*/
}

.gridSummaryBar > div {
    display: flex;
    justify-content: center;
    width: 20%;
}