.mainContainer {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.inputContainer {
  height: 200px;
  width: calc(100% - 5px);
  background-color: white;
  margin-top: 15px;
}

.imageLabel {
  width: 98.5%;
  border-style: solid;
  border-width: thin;
  border-color: #c0c0c0;
  display: inline-block;
  text-align: right;
  padding: 4px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.currentImageContainer {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
}