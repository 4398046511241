.ag-grid-custom-selected-row-color-by-click-row {
    background-color: #b7e4ff !important;
}

/*Ag-grid spesific CSS definitions*/
.ag-menu-list>.ag-menu-option>.ag-menu-option-icon {
    padding-top: 0;
    padding-bottom: 0;
}

.ag-menu-list>.ag-menu-separator {
    height: 0!important;
}

.ag-menu-option-icon > img {
    height: 18px;
    width: 18px;
    padding-top: 5px;
}



.ag-grid-deleted-row {
    color:#d0cece!important;
}
