legend.out-legend {
    color:black;
    padding:10px;
    margin:10px;
}

.form-main-container div.input-container {
    display: flex;
    /*width: 33%;*/
    padding-top: 2px;
    font-size:inherit;
    font-family: inherit;
}

.form-main-container input[type=text],
.form-main-container input[type=number],
.form-main-container input[type=datetime-local],
.form-main-container .select-container{
    width: 54%;
    margin-left: 3px;
    font-size:inherit;
    font-family: inherit;
}

.form-main-container .select-container> select {
    width:101.3%;
    height: 100%;
    font-size:inherit;
    font-family: inherit;
}

.form-main-container .radio-container{
    width: 55%;
    margin-left: 0%;
    display: flex;
    align-items: center;
}

.form-main-container .radio-container>label{
    margin-left: 5%;
}

.form-main-container .radio-container input{
    
}

.input-container > label {
    /*Input solundaki label*/
    width: 40%;
    border-style: solid;
    border-width: thin;
     border-color: #c0c0c0;
    display: inline-block;
    text-align: right;
    padding: 4px;
    font-size:inherit;
    font-family: inherit;
}

.input-container .radio-label {
    /*Radio input içeren labellar*/

}

