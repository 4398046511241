@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
.ag-grid-custom-selected-row-color-by-click-row {
    background-color: #b7e4ff !important;
}

/*Ag-grid spesific CSS definitions*/
.ag-menu-list>.ag-menu-option>.ag-menu-option-icon {
    padding-top: 0;
    padding-bottom: 0;
}

.ag-menu-list>.ag-menu-separator {
    height: 0!important;
}

.ag-menu-option-icon > img {
    height: 18px;
    width: 18px;
    padding-top: 5px;
}



.ag-grid-deleted-row {
    color:#d0cece!important;
}

legend.out-legend {
    color:black;
    padding:10px;
    margin:10px;
}

.form-main-container div.input-container {
    display: flex;
    /*width: 33%;*/
    padding-top: 2px;
    font-size:inherit;
    font-family: inherit;
}

.form-main-container input[type=text],
.form-main-container input[type=number],
.form-main-container input[type=datetime-local],
.form-main-container .select-container{
    width: 54%;
    margin-left: 3px;
    font-size:inherit;
    font-family: inherit;
}

.form-main-container .select-container> select {
    width:101.3%;
    height: 100%;
    font-size:inherit;
    font-family: inherit;
}

.form-main-container .radio-container{
    width: 55%;
    margin-left: 0%;
    display: flex;
    align-items: center;
}

.form-main-container .radio-container>label{
    margin-left: 5%;
}

.form-main-container .radio-container input{
    
}

.input-container > label {
    /*Input solundaki label*/
    width: 40%;
    border-style: solid;
    border-width: thin;
     border-color: #c0c0c0;
    display: inline-block;
    text-align: right;
    padding: 4px;
    font-size:inherit;
    font-family: inherit;
}

.input-container .radio-label {
    /*Radio input içeren labellar*/

}


body {
    overflow-x: auto;
    overflow-y: hidden;
}

.alignRight {
    text-align: right;
}

/*sipariş tablosundaki operatör ilerinin kırmızı gösterilmesi için*/
.custom-cell-red {
    background: #e36c6c;
}
* {
/*  box-sizing: border-box;
  margin: 0;
  padding: 0;*/
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.Kategori_mainContainer__28mBe {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.Kategori_inputContainer__2Ba3x {
  height: 155px;
  width: calc(100% - 5px);
  background-color: white;
  margin-top: 15px;
}

.Kategori_imageLabel__1mMrm {
  width: 98.5%;
  border-style: solid;
  border-width: thin;
  border-color: #c0c0c0;
  display: inline-block;
  text-align: right;
  padding: 4px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.Kategori_currentImageContainer__W1xxm {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
}
/* isPopup true dönünce alttaki CSS'ler gerekli olmuyor.
#editable-grid > div > div > div > div > div> div  > div > div > div.ag-row,
#editable-grid > di >*,
#editable-grid > div > div>*,
#editable-grid > div > div > div>*,
#editable-grid > div > div > div > div>*,
#editable-grid > div > div > div > div > div>*,
#editable-grid > div > div > div > div > div> div>*,
#editable-grid > div > div > div > div > div> div > div>*,
#editable-grid > div > div > div > div > div> div > div >div>*,
#editable-grid > div > div > div > div > div> div > div >div > div>*,
#editable-grid > div > div > div > div > div> div > div >div > div > div>*
{
    transform: none!important;
}

*/

.PopModal_footer__1ki-u {
    display:flex;
    flex-wrap: wrap;
}

.PopModal_footer__1ki-u button span {
    line-height: initial;
    text-transform: initial;
}
/* The Modal (background) */
.Modal_modal__P3_V5 {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.Modal_modalContent__1rYYD {
    position: absolute;
    /*-webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);*/
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #4c4848;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: Modal_animatetop__eULZH;
    -webkit-animation-duration: 0.4s;
    animation-name: Modal_animatetop__eULZH;
    animation-duration: 0.4s
}

/* Add Animation */

@keyframes Modal_animatetop__eULZH {
    from {opacity:0}
    to {opacity:1}
}

.Modal_modalHeader__2ik2T {
    padding: 2px 10px;
    background-color: rgb(63, 81, 181);
    color: white;
    cursor: move;

    display: flex;
    align-items: center;
}

.Modal_headerContent__XRd-r {
    width: 20px;

}


.Modal_headerCloseButtonContainer__9wuYT {
    width: calc(100% - 20px) ;
}


/* The Close Button */
.Modal_close__3rW_Y {
    color: white;
    float: right;
    font-size: 23px;
    font-weight: bold;
    margin-top: -4px;
}

.Modal_close__3rW_Y:hover,
.Modal_close__3rW_Y:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}


.Modal_modalBody__1CdAm {

}

.Modal_modalFooter__2dKHi {
    padding: 7px 8px;
    background-color: rgb(63, 81, 181);
    color: white;
}


.Calendar_dateTimeCustom__KbvcC {
    border: 1px solid #c0c0c0;
    margin-left: 3px;
    padding-right: 2px;
    background-color: white;
    width: 54%;
    height: 27px;
}

.Calendar_dateTimeCustom__KbvcC > div {
    bottom: 23px;
    left: 15px;
}

.Calendar_dateTimeCustom__KbvcC > input[type='text'] {
    width: calc(100% - 16px);
    border: none;
    padding-left: 2px;
    outline: none;
    margin-top: 3px;
}

.Calendar_dateTimeCustom__KbvcC > span {
    font-size: 8px;
    color: black;
    cursor: context-menu;
    position: absolute;
    padding-top: 8px;
}


.Toolbar_toolBarContainer__2vdVc {
    display: flex;
    border-style: solid;
    border-width: thin;
    border-color: rgb(214, 211, 211);
    background-image: linear-gradient(to top, #d4d5d6, #fbfcfd);
    box-shadow: cadetblue;
    box-shadow: 0px 0.7px 0.9px 0px #888888;
    margin: 1px;
    height: 25px;
}

.Toolbar_toolBarContainer__2vdVc>div {
    padding-right: 3px;
    border-width: thin;
    border-radius: 4px;
    border-style: solid;
    border-color: transparent;
    display: flex;
}

.Toolbar_toolBarContainer__2vdVc>div>img {
    width: 20px;
    display: block;
    margin: 3px auto;
}

.Toolbar_toolBarContainer__2vdVc>div:hover {
    background-color: #fdf758;
    border-color: rgb(192, 192, 192);
}

.Toolbar_separatorDiv__zkiBf {
    display: flex;
    height: 18px;
    margin-top: 3px;
    border-radius: 10px;
    width: 2px;
    background-color: #acacad;
    margin-right: 4px;
    box-shadow: -1px 2px 0.4px 0.2px #888888;
}

.Toolbar_checkboxContainer__1_iXy {
    display: flex;
    flex-direction: column;
}

.Toolbar_checkboxContainer__1_iXy> div {
    padding-top: 5px;
    padding-left: 5px;
}

.Grid_gridSummaryBar__3E0Uu {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 8px;
    left: 5px;
    right: 0;
    border-color: rgb(192, 192, 192);
    border-width: thin;
    border-style: solid;
    border-radius: 3px;
    /*height: 27px;*/
}

.Grid_gridSummaryBar__3E0Uu > div {
    display: flex;
    justify-content: center;
    width: 20%;
}
.ImageToolTip_toolTip__1WBjZ {
    position: absolute;
    width: 1200px;
    height: 1200px;
    border: 1px solid cornflowerblue;
    background-color: #0c0c0c;
}
.Product_mainContainer__3yv44 {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.Product_inputContainer__vFG4l {
  height: 250px;
  width: calc(100% - 5px);
  background-color: white;
  margin-top: 15px;
}

.Product_imageLabel__1HYH5 {
  width: 98.5%;
  border-style: solid;
  border-width: thin;
  border-color: #c0c0c0;
  display: inline-block;
  text-align: right;
  padding: 4px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.Product_currentImageContainer__1LjpW {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
}
.Order_mainContainer__2nB0a {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.Order_inputContainer__LrVfy {
  height: 170px;
  width: calc(100% - 5px);
  background-color: white;
  margin-top: 15px;
}

.Order_imageLabel__H1u4t {
  width: 98.5%;
  border-style: solid;
  border-width: thin;
  border-color: #c0c0c0;
  display: inline-block;
  text-align: right;
  padding: 4px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.Order_currentImageContainer__2VsvH {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
}
.Menu_mainContainer__C3ymw {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.Menu_inputContainer__1t5et {
  height: 170px;
  width: calc(100% - 5px);
  background-color: white;
  margin-top: 15px;
}

.Menu_imageLabel__EEz5U {
  width: 98.5%;
  border-style: solid;
  border-width: thin;
  border-color: #c0c0c0;
  display: inline-block;
  text-align: right;
  padding: 4px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.Menu_currentImageContainer__3CDXB {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
}
.Opportunity_mainContainer__3OBg7 {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.Opportunity_inputContainer__2dn82 {
  height: 200px;
  width: calc(100% - 5px);
  background-color: white;
  margin-top: 15px;
}

.Opportunity_imageLabel__2M5Sl {
  width: 98.5%;
  border-style: solid;
  border-width: thin;
  border-color: #c0c0c0;
  display: inline-block;
  text-align: right;
  padding: 4px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.Opportunity_currentImageContainer__3syIV {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
}
.User_mainContainer__2i_hr {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.User_inputContainer__D0-jw {
  height: 170px;
  width: calc(100% - 5px);
  background-color: white;
  margin-top: 15px;
}

.User_imageLabel__1MSBu {
  width: 98.5%;
  border-style: solid;
  border-width: thin;
  border-color: #c0c0c0;
  display: inline-block;
  text-align: right;
  padding: 4px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.User_currentImageContainer__oITfM {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
}
.MostSearchedWords_mainContainer__B5Y0L {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.MostSearchedWords_inputContainer__1Gccq {
  height: 170px;
  width: calc(100% - 5px);
  background-color: white;
  margin-top: 15px;
}

.MostSearchedWords_imageLabel__y1TQ_ {
  width: 98.5%;
  border-style: solid;
  border-width: thin;
  border-color: #c0c0c0;
  display: inline-block;
  text-align: right;
  padding: 4px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.MostSearchedWords_currentImageContainer__2faIu {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
}
.SalesSummary_mainContainer__1CWab {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.Coupon_mainContainer__2_xYT {
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.Coupon_inputContainer__n0ypC {
  height: 225px;
  width: calc(100% - 5px);
  background-color: white;
  margin-top: 15px;
}

.Coupon_imageLabel__1DzUv {
  width: 98.5%;
  border-style: solid;
  border-width: thin;
  border-color: #c0c0c0;
  display: inline-block;
  text-align: right;
  padding: 4px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.Coupon_currentImageContainer__vkxuH {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
}
.RobotRemoteproject_robotContainer__2UaYT{
    display: flex;
    flex-wrap: wrap;
    --firstColor: #317FFB;
    --componentFirstColor: #000;
    --componentSecondColor:#1D1D1D;
    --componentThirdColor:#151515;
    --textColor: #fff;
    --textColorTwo:#979797;
    --buttonBackColor:#A0A0A0;
    --buttonNextColor:#33C43D;
    
    /* background-color: var(--componentFirstColor); */
    height: 100%;
    font-family: 'Quicksand', sans-serif!important;
    margin-top: 65px;
}
.RobotRemoteproject_robot2Container__164Ab{
    font-family: 'Quicksand', sans-serif!important;
     flex-grow: 1;
     padding: 30px 10px 10px 10px;
     height: 100%;
     overflow-y: auto;
     overflow-x: hidden;
    --firstColor: #317FFB;
    --componentFirstColor: #000;
    --componentSecondColor:#1D1D1D;
    --componentThirdColor:#151515;
    --textColor: #fff;
    --textColorTwo:#979797;
    --buttonBackColor:#A0A0A0;
    --buttonNextColor:#33C43D;
}
.RobotRemoteproject_robotContainer__2UaYT *{
font-family: inherit;
}
.RobotRemoteproject_robot2Container__164Ab *{
    font-family: inherit;
    }
.RobotRemoteproject_container__1D3Q8{
    margin-right: 5px;
}
.RobotRemoteproject_flexColumn__3QQFy{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.RobotRemoteproject_controlBar__rMxWk{
    margin-right: 5px;
}
.RobotRemoteproject_flexContainer__1bX0t{
    display: flex;
    flex-wrap: wrap;
    flex:1 1;
    margin-bottom: 10px;
}
.RobotRemoteproject_flexItem__3pK_K{
    flex:1 1;
}
.RobotRemoteproject_robotInfoContainer__9nuf9 *{
    flex:1 1;
}
.RobotRemoteproject_barButton__3zDut{
    flex: 1 1;
    font-size: 1.2rem;
    color: var(--textColor);
    padding: 1.5rem 0;
    margin: .25rem;
    background-color: var(--firstColor);
    border: none;
    border-radius: .5rem;   
    min-width: 60px;
}
.RobotRemoteproject_robotInfoContainer__9nuf9{
    display: flex;
    height: 250px;
    width: 100%;
}
.RobotRemoteproject_StateContainer__10jiE{
    display: flex;
    background-color: var(--componentFirstColor);
    color: var(--textColor);
}
.RobotRemoteproject_stateText__1G0vt{
    flex:1 1;
    text-align: center;
}
.RobotRemoteproject_stateValue__1DaWe{
    flex:2 1;
    text-align: center;
    background-color: var(--textColorTwo);
}

@media screen and (max-width:620px){
    .RobotRemoteproject_robotContainer__2UaYT *{
        font-size: 12px !important;
    }
    .RobotRemoteproject_barButton__3zDut{
        padding: 0.5rem 0;
    }
}
.ControlContainer_title__XLvlp{
    text-align: center;
}
.ControlContainer_container__2aoHk{
    background-color: #151515;
    color: white;
    border-radius: 10px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: center;
}
.ControlContainer_value__2PwYv{
    background-color: #1D1D1D;
    padding: 20px;
    text-align: center;
    border-radius : 0 0 10px 10px;
    flex: 2 1;
}
.ControlContainer_value__2PwYv p{
    margin: 0;
    padding: 0;
    font-weight: bold;
}
.StateWithIcon_item__2jtCR{
    display: flex;
    flex-direction: column;
}
.StateWithIcon_value__2Q606{
    
}
.RobotPayload_container__1oO57{
    text-align: center;
    position: relative;
}

.RobotPayload_robotImage__3qe1l{
    max-height: 100%;
    max-width: 90%;
}

.RobotPayload_robotBasketsContainer__29yb6{
    position: absolute;
    width: 70%;
    height: 40%;
    top: 25%;
    left: 15%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: rgba(255,255,255, 0.2);
    border-radius: .4rem;
}

.RobotPayload_robotBasketTekliKutu__2IKNR{
    width: 48%;
    height: 48%;
    background-color: var(--firstColor);
    border: 1px solid rgb(73, 140, 241);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: .4rem;
}

.RobotPayload_robotBasketTekliKutuNull__3ud4E{
    width: 47%;
    height: 50%;
    background-color: transparent;
    border: transparent;
}

.RobotPayload_robotBasket2liKutuUst__1qc8j{
    width: 95%;
    height: 50%;
    background-color: var(--firstColor);
    border: 1px solid rgb(73, 140, 241);
    position: absolute;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .4rem;
}

.RobotPayload_robotBasket2liKutuAlt__2V4H-{
    width: 95%;
    height: 50%;
    background-color: var(--firstColor);
    border: 1px solid rgb(73, 140, 241);
    position: absolute;
    bottom:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .4rem;
}

.RobotPayload_robotBasket4lü__1_PEo{
    width: 98%;
    height: 98%;
    background-color: var(--firstColor);
    border: 1px solid rgb(73, 140, 241);
    position: absolute;
    bottom:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .4rem;
}

.RobotPayload_robotBasketSiteName__vPnxr{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
}
.RobotControl_autonomiContainer__3NpnO{
    width: 62%;
    height: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
.RobotControl_buttonsContainer__3qamf{
    width: 250px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.RobotControl_buttons__2XALd{
    margin: 2px;
    padding: 10px;
    font-size: 1rem;
    width: 120px;
    height: 70px;
    border-radius: .4rem;
    background-color: var(--firstColor);
    border: none;
    color: var(--textColor);
    cursor: pointer;
}

.RobotControl_buttons__2XALd:hover{
   background-color: rgb(1, 96, 219);
}

.RobotControl_controlContainer__3rB1L{
    width: 72%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.RobotControl_enableDisableControl__5qsjB{
    width: 100%;
    background-color: var(--firstColor);
    border: none;
    border-radius: 10px;
    height: auto;
    padding: 10px;
    color: var(--textColor);
    display: flex;
    align-items: center;
    justify-content: center;
}

.RobotControl_inputRadioButtons__3s16D{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    height: 50px;
}

.RobotControl_wasdButtons__3kF2a{
    margin-top: 15px;
    width: 160px;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
}
.RobotControl_wasd__2C1ts{
    width: 50px;
    height: 50px;
    background-color: var(--firstColor);
    border: 1px solid #000;
    border-radius: 5px;
    font-weight: bold;
}
.RobotControl_wasd__2C1ts:hover{
    border: 1px solid yellow;
}
.RobotControl_wasd__2C1ts:first-child{
    margin:0 50px;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.RobotControl_wasd__2C1ts:nth-child(2){
    display:flex;
    justify-content: flex-start;
    align-items: center;
}
.RobotControl_wasd__2C1ts:nth-child(3){
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.RobotControl_wasd__2C1ts:nth-child(4){
    display:flex;
    justify-content: flex-end;
    align-items: center;
}


.RobotLive_liveMapContainer__3YIFh{
    position: relative;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #1a1b1c;
}
.RobotLive_liveMapContainer__3YIFh *{
    height: 100%;
    width: 95%;
}
.RobotLive_gpsContainer__14_Mj{
    width: -webkit-min-content;
    width: min-content;
    height: -webkit-min-content;
    height: min-content;
    position: absolute;
    left: 85%;
    bottom: 0%;
    top: 78%;
}
.RobotLive_zoomInOut__j9asU{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    background-color: #dee5eb;
    height: 57px;
    width: 39px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}
.RobotLive_gpsIcon__2MoeO{
    background-color: #dee5eb;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
    display: block;
    height: 29px;
    overflow: hidden;
    cursor: pointer;
    color: #263238;
    transition: background-color .16s ease-out;
}
.ChooseProject_overlay__XqnII {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ChooseProject_popup__21k76 {
    margin: 70px auto;
    padding: 20px;
    background: var(--componentThirdColor);
    border-radius: 5px;
    width: 30%;
    height: 50%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .ChooseProject_popup__21k76 h2 {
    margin-top: 10px;
    color: rgb(211, 208, 208);
    font-family: 'Quicksand', sans-serif!important;
    font-size: 16px;
    
  }
  .ChooseProject_popup__21k76 .ChooseProject_close__2y8xh {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .ChooseProject_popup__21k76 .ChooseProject_close__2y8xh:hover {
    cursor: pointer;
    color: #000;
  }
  .ChooseProject_popup__21k76 .ChooseProject_content__1ehD3 {
    max-height: 50%;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 30px;
  }
 
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

  .ChooseProject_content__1ehD3 input {
    margin: 15px 15px 0 0;
  }

  .ChooseProject_button__3INlK{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,-50%);
  }

  .ChooseProject_okeyButton__3iPFK{
      padding: 10px;
      width: 150px;
      text-align: center;
      background-color: var(--textColor);
      border:1px solid #000;
  }
  
  @media screen and (max-width: 700px) {
    .ChooseProject_popup__21k76 {
      width: 70%;
    }
  }
  
.RobotCallModal_floorContainer__2pEi4{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
}

.RobotCallModal_floorInnerContainer__3BQtv{
    width: 90%;
    height: 75px;
    background: #1d1d1d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.959);
    border-radius: 20px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-bottom:20px;
    overflow-y: hidden;
}

.RobotCallModal_floorInnerContainer__3BQtv::-webkit-scrollbar {
    height: 7px;
}

.RobotCallModal_poseInnerContainer__13HB2{
    width: 90%;
    height: 225px;
    background: #1d1d1d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.959);
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
}

.RobotCallModal_siteItem__24bui,.RobotCallModal_floorItem__1z_g0,.RobotCallModal_roomItem__2DzS5{
    background-color: #A0A0A0;
    border: none;
    font-size: 20px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px 30px;
    border-radius: 7px;
    color: aliceblue;
    font-weight: 500;
    margin: 10px;
}

.RobotCallModal_active__1CR_0{
    background-color: #317FFB;
}
.Main_mainContainer__3DLd3 {
    position: fixed;
    top: 64px;
    bottom: 34px;
    width: calc(100% - 255px);
    left: 250px;
}

.Main_tabHeaderContainer__74WRb {
    background-color: rgb(245, 245, 245);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.Main_footerContainer__W9QXq {
    position: fixed;
    z-index:-2;
    bottom: 7px;
    width: calc(100% - 255px);
    left: 250px;
    height: 27px;
    display: flex;
    text-align: center;
    border-radius: 2px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    border-color: rgb(192, 192, 192);
    border-width: thin;
    border-style: solid;
    font-size: 13px;
    align-items: center;
}

.Main_tabPanel__39p6q {
    height: calc(100% - 45px);
    /*overflow-y: auto;
    margin-top: 5px;*/
}

.Main_cancelTabDiv__3zymG {
    padding-left: 25px;
    text-transform: none;
}

.Main_cancelTabDiv__3zymG svg{
    visibility: hidden;
    margin-bottom: -2px;
    padding-left: 10px;
}

.Main_cancelTabDiv__3zymG:hover svg {
    color:red;
    visibility: visible;
}


.ElevatorCall_mainContainer__2cMrH > div {
    width: 100%;
    padding: 5px 0 5px 0;
}

.ElevatorCall_mainContainer__2cMrH  textarea {
    width: 100%;
    height: 50px;
}
.CardQrGenerate_mainContainer__1bjql > div {
    width: 100%;
    padding: 1px 0 1px 0;
}

.CardQrGenerate_mainContainer__1bjql  textarea {
    width: 100%;
    height: 50px;
}

.CardQrGenerate_buttonContainer__1X74W {
    display: grid;
    grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
}

.CardQrGenerate_showPassivesButtonContainer__1zO7t {
    display: flex;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.CardQrLogin_dialogContent__1ytyY {
  display: grid;
  grid-template-columns: 1fr;
}
.CardQrAdmin_mainContainer__2a1jX {
  position: fixed;
  /*display: grid;*/
  grid-auto-flow: row;
  grid-gap: 5px 15px;
  gap: 5px 15px;
  overflow: scroll;
  height: 85%;
  width: 99%;
}

.CardQrAdmin_group__2PpWQ {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px ;
  width: 99%;
}

.CardQrAdmin_group__2PpWQ > label {
  font-weight: bolder;
}



.CardQrAdmin_elevator__1VJyb {
  background-color: #cdcdcd;
  padding-top: 5px ;
  padding-bottom: 5px ;
  width: calc(100% - 20px);
  border: #adadad solid
}

.CardQrAdmin_elevator__1VJyb > label {
  color: #1b1b78;
  font-weight: bold;
  padding-right: 10px ;
}

.CardQrAdmin_elevator__1VJyb .CardQrAdmin_floorNames__2uN6q {
  word-wrap: break-word;
}

.CardQrAdmin_floor__G33JM  {
  padding-left: 5px ;
}
.Alert_modalContent__3DxvQ {
    position: absolute;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    outline: none;
}

.Alert_modalHeader__15Es0 {
    padding: 2px 10px;
    background-color: rgb(63, 81, 181);
    color: white;
    display: flex;
    align-items: center;
}

.Alert_headerCloseButtonContainer__UvGK4 {
    width: calc(100% - 20px);
}

.Alert_alertBodyMainDiv__2f9-i {
    display: flex;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    min-height: 90px;
}

.Alert_alertIconDiv__3FgnZ {
    width: 20%;
}

.Alert_alertMessageDiv__3_X_v {
    width: 80%;
    font-size: 13px;
    padding-top: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.Alert_alertButtonDiv__1hMVD {

    text-align: right;
}

.Alert_alertButton__3ks1Y {
    font-size: 13px;
    margin: 10px;
    background-color: rgb(232, 232, 239);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.20);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 4px;
}

.Alert_alertButtonDiv__1hMVD * {
    text-transform: none;
}

.Alert_alertHeaderText__jeDwW {
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    position: relative;
    left: -10px;
}

