.toolBarContainer {
    display: flex;
    border-style: solid;
    border-width: thin;
    border-color: rgb(214, 211, 211);
    background-image: linear-gradient(to top, #d4d5d6, #fbfcfd);
    box-shadow: cadetblue;
    box-shadow: 0px 0.7px 0.9px 0px #888888;
    margin: 1px;
    height: 25px;
}

.toolBarContainer>div {
    padding-right: 3px;
    border-width: thin;
    border-radius: 4px;
    border-style: solid;
    border-color: transparent;
    display: flex;
}

.toolBarContainer>div>img {
    width: 20px;
    display: block;
    margin: 3px auto;
}

.toolBarContainer>div:hover {
    background-color: #fdf758;
    border-color: rgb(192, 192, 192);
}

.separatorDiv {
    display: flex;
    height: 18px;
    margin-top: 3px;
    border-radius: 10px;
    width: 2px;
    background-color: #acacad;
    margin-right: 4px;
    box-shadow: -1px 2px 0.4px 0.2px #888888;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
}

.checkboxContainer> div {
    padding-top: 5px;
    padding-left: 5px;
}
