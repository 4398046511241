.mainContainer {
  position: fixed;
  /*display: grid;*/
  grid-auto-flow: row;
  gap: 5px 15px;
  overflow: scroll;
  height: 85%;
  width: 99%;
}

.group {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px ;
  width: 99%;
}

.group > label {
  font-weight: bolder;
}



.elevator {
  background-color: #cdcdcd;
  padding-top: 5px ;
  padding-bottom: 5px ;
  width: calc(100% - 20px);
  border: #adadad solid
}

.elevator > label {
  color: #1b1b78;
  font-weight: bold;
  padding-right: 10px ;
}

.elevator .floorNames {
  word-wrap: break-word;
}

.floor  {
  padding-left: 5px ;
}