.mainContainer {
    position: fixed;
    top: 64px;
    bottom: 34px;
    width: calc(100% - 255px);
    left: 250px;
}

.tabHeaderContainer {
    background-color: rgb(245, 245, 245);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.footerContainer {
    position: fixed;
    z-index:-2;
    bottom: 7px;
    width: calc(100% - 255px);
    left: 250px;
    height: 27px;
    display: flex;
    text-align: center;
    border-radius: 2px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    border-color: rgb(192, 192, 192);
    border-width: thin;
    border-style: solid;
    font-size: 13px;
    align-items: center;
}

.tabPanel {
    height: calc(100% - 45px);
    /*overflow-y: auto;
    margin-top: 5px;*/
}

.cancelTabDiv {
    padding-left: 25px;
    text-transform: none;
}

.cancelTabDiv svg{
    visibility: hidden;
    margin-bottom: -2px;
    padding-left: 10px;
}

.cancelTabDiv:hover svg {
    color:red;
    visibility: visible;
}

