@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
.robotContainer{
    display: flex;
    flex-wrap: wrap;
    --firstColor: #317FFB;
    --componentFirstColor: #000;
    --componentSecondColor:#1D1D1D;
    --componentThirdColor:#151515;
    --textColor: #fff;
    --textColorTwo:#979797;
    --buttonBackColor:#A0A0A0;
    --buttonNextColor:#33C43D;
    
    /* background-color: var(--componentFirstColor); */
    height: 100%;
    font-family: 'Quicksand', sans-serif!important;
    margin-top: 65px;
}
.robot2Container{
    font-family: 'Quicksand', sans-serif!important;
     flex-grow: 1;
     padding: 30px 10px 10px 10px;
     height: 100%;
     overflow-y: auto;
     overflow-x: hidden;
    --firstColor: #317FFB;
    --componentFirstColor: #000;
    --componentSecondColor:#1D1D1D;
    --componentThirdColor:#151515;
    --textColor: #fff;
    --textColorTwo:#979797;
    --buttonBackColor:#A0A0A0;
    --buttonNextColor:#33C43D;
}
.robotContainer *{
font-family: inherit;
}
.robot2Container *{
    font-family: inherit;
    }
.container{
    margin-right: 5px;
}
.flexColumn{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.controlBar{
    margin-right: 5px;
}
.flexContainer{
    display: flex;
    flex-wrap: wrap;
    flex:1;
    margin-bottom: 10px;
}
.flexItem{
    flex:1;
}
.robotInfoContainer *{
    flex:1;
}
.barButton{
    flex: 1 1;
    font-size: 1.2rem;
    color: var(--textColor);
    padding: 1.5rem 0;
    margin: .25rem;
    background-color: var(--firstColor);
    border: none;
    border-radius: .5rem;   
    min-width: 60px;
}
.robotInfoContainer{
    display: flex;
    height: 250px;
    width: 100%;
}
.StateContainer{
    display: flex;
    background-color: var(--componentFirstColor);
    color: var(--textColor);
}
.stateText{
    flex:1 1;
    text-align: center;
}
.stateValue{
    flex:2 1;
    text-align: center;
    background-color: var(--textColorTwo);
}

@media screen and (max-width:620px){
    .robotContainer *{
        font-size: 12px !important;
    }
    .barButton{
        padding: 0.5rem 0;
    }
}