.container{
    text-align: center;
    position: relative;
}

.robotImage{
    max-height: 100%;
    max-width: 90%;
}

.robotBasketsContainer{
    position: absolute;
    width: 70%;
    height: 40%;
    top: 25%;
    left: 15%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: rgba(255,255,255, 0.2);
    border-radius: .4rem;
}

.robotBasketTekliKutu{
    width: 48%;
    height: 48%;
    background-color: var(--firstColor);
    border: 1px solid rgb(73, 140, 241);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: .4rem;
}

.robotBasketTekliKutuNull{
    width: 47%;
    height: 50%;
    background-color: transparent;
    border: transparent;
}

.robotBasket2liKutuUst{
    width: 95%;
    height: 50%;
    background-color: var(--firstColor);
    border: 1px solid rgb(73, 140, 241);
    position: absolute;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .4rem;
}

.robotBasket2liKutuAlt{
    width: 95%;
    height: 50%;
    background-color: var(--firstColor);
    border: 1px solid rgb(73, 140, 241);
    position: absolute;
    bottom:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .4rem;
}

.robotBasket4lü{
    width: 98%;
    height: 98%;
    background-color: var(--firstColor);
    border: 1px solid rgb(73, 140, 241);
    position: absolute;
    bottom:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .4rem;
}

.robotBasketSiteName{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
}