.mainContainer > div {
    width: 100%;
    padding: 1px 0 1px 0;
}

.mainContainer  textarea {
    width: 100%;
    height: 50px;
}

.buttonContainer {
    display: grid;
    grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
}

.showPassivesButtonContainer {
    display: flex;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}