/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modalContent {
    position: absolute;
    /*-webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);*/
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #4c4848;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {opacity:0}
    to {opacity:1}
}

@keyframes animatetop {
    from {opacity:0}
    to {opacity:1}
}

.modalHeader {
    padding: 2px 10px;
    background-color: rgb(63, 81, 181);
    color: white;
    cursor: move;

    display: flex;
    align-items: center;
}

.headerContent {
    width: 20px;

}


.headerCloseButtonContainer {
    width: calc(100% - 20px) ;
}


/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 23px;
    font-weight: bold;
    margin-top: -4px;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}


.modalBody {

}

.modalFooter {
    padding: 7px 8px;
    background-color: rgb(63, 81, 181);
    color: white;
}

